// mermaid@8.4.2
@import 'flowchart';
@import 'sequence';
@import 'gantt';
@import 'class';
@import 'git';
@import 'pie';
@import 'state';

// .composit {
//   fill: white;
//   border-bottom: 1px
// }

:root {
  --mermaid-font-family: '"trebuchet ms", verdana, arial';
  --mermaid-font-family: "Comic Sans MS", "Comic Sans", cursive;
  // --mermaid-alt-font-family: '"Lucida Console", Monaco, monospace';
}

