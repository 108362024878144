.home-intro {
  transform: translateY(0);
  padding: 2rem 0 2rem 0;

  .home-avatar {
    img {
      width: 6rem;
    }
  }
}

.post {
  padding-top: 1rem;
  padding-bottom: .8rem;
  color: $global-font-color;
  border-bottom: 1px dashed $global-border-color;

  .dark-theme & {
    color: $global-font-color-dark;
    border-bottom: 1px dashed $global-border-color-dark;
  }

  .post-featured-image-preview {
    width: 100%;
    padding: 30% 0 0;
    position: relative;
    margin: 0.6rem auto;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }

  .post-list-title {
    font-size: 1.6rem;
  }

  .post-meta {
    font-size: .875rem !important;
    a {
      color: $global-font-secondary-color !important;

      .dark-theme & {
        color: $global-font-secondary-color-dark !important;
      }

      &:hover {
        color: $global-link-hover-color !important;

        .dark-theme & {
          color: $global-link-hover-color-dark !important;
        }
      }
    }
  }

  .post-content {
    display: -webkit-box;
    margin-top: .3rem;
    width: 100%;
    max-height: 10rem;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-size: 1rem;
      margin: 0;
      padding: .2rem 0;
      overflow: hidden;
      overflow-wrap: break-word;
      text-overflow: ellipsis;
    }

    a {
      word-break: break-all;
    }
  }

  .post-footer {
    margin-top: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: .875rem !important;

    a {
      color: $post-link-color !important;

      .dark-theme & {
        color: $post-link-color-dark !important;
      }

      &:hover {
        color: $post-link-hover-color !important;

        .dark-theme & {
          color: $post-link-hover-color-dark !important;
        }
      }
    }

    .post-tags {
      padding: 0;

      a {
        color: $global-link-color !important;

        .dark-theme & {
          color: $global-link-color-dark !important;
        }

        &:hover {
          color: $global-link-hover-color !important;

          .dark-theme & {
            color: $global-link-hover-color-dark !important;
          }
        }
      }
    }
  }
}