/** Post **/
.post-title {
  margin: 0 !important;
  font-size: 1.8rem;
  line-height: 3rem;
}

.post-meta {
  font-size: .88rem;
  color: $global-font-secondary-color;

  span {
    display: inline-block;
  }

  .dark-theme & {
    color: $global-font-secondary-color-dark;
  }

  a {
    color: $post-link-color;

    .dark-theme & {
      color: $post-link-color-dark;
    }

    &:hover {
      color: $post-link-hover-color;

      .dark-theme & {
        color: $post-link-hover-color-dark;
      }
    }
  }

  .author {
    font-size: 1.05rem;
  }
}

.post-featured-image {
  padding-top: .6rem;

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
  }
}

@import "../_partial/_post/toc";

.post-content {
  .post-dummy-target:target {
    display: inline-block;
    position: relative;
    top: -5.6rem;
    visibility: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;

    .dark-theme & {
      font-weight: bolder;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: .8rem;
    padding-bottom: .3rem;
  }

  h2::before {
    content: "#";
    margin-right: .3125rem;
    color: $post-link-color;

    .dark-theme & {
      color: $post-link-color-dark;
    }
  }

  h3::before,
  h4::before,
  h5::before,
  h6::before {
    content: "|";
    margin-right: .3125rem;
    color: $post-link-color;

    .dark-theme & {
      color: $post-link-color-dark;
    }
  }

  p {
    font-size: 1rem;
    margin: .5rem 0;
  }

  a {
    color: $post-link-color;

    .dark-theme & {
      color: $post-link-color-dark;
    }
  }

  a:hover {
    color: $post-link-hover-color;

    .dark-theme &:hover {
      color: $post-link-hover-color-dark;
      font-weight: bold;
    }
  }

  ul {
    padding-left: 2rem;
    list-style-type: disc;
  }

  ruby {
    background: $code-background-color;

    rt {
      color: $global-font-secondary-color;
    }

    .dark-theme & {
      background: $code-background-color-dark;

      rt {
        color: $global-font-secondary-color-dark;
      }
    }
  }

  .table-wrapper {
    overflow-x: auto;

    &::-webkit-scrollbar {
      background-color: $table-background-color;

      .dark-theme & {
        background-color: $table-background-color-dark;
      }
    }

    > table {
      width: 100%;
      max-width: 100%;
      margin: .625rem 0;
      border-spacing: 0;
      background: $table-background-color;

      .dark-theme & {
        background: $table-background-color-dark;
      }

      thead {
        background: $table-thead-color;

        .dark-theme & {
          background-color: $table-thead-color-dark;
        }
      }

      th, td {
        padding: .3rem 1rem;
        border: 1px double $global-border-color;

        .dark-theme & {
          border: 1px double $global-border-color-dark;
        }
      }
    }
  }

  figure {
    text-align: center;
  }

  .image-caption:not(:empty) {
    min-width: 20%;
    max-width: 80%;
    display: inline-block;
    padding: .625rem;
    margin: 0 auto;
    border-bottom: 1px solid #d9d9d9;
    font-size: .875rem;
    color: #969696;
    line-height: 1.7;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
  }

  blockquote {
    font-size: 1rem;
    display: block;
    border-width: 1px 0;
    border-style: solid;
    border-color: $global-border-color;
    padding: 1.5em 1.2em 0.5em 1.2em;
    margin: 0 0 2em 0;
    position: relative;

    &::before {
      content: '\201C';
      position: absolute;
      top: 0em;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 3rem;
      height: 2rem;
      font: 6em/1.08em 'PT Sans', sans-serif;
      color: $post-link-color;
      text-align: center;

      .dark-theme & {
        color: $post-link-color-dark;
      }
    }

    &::after {
      content: '#blockquote' attr(cite);
      display: block;
      text-align: right;
      font-size: 0.875em;
      color: $post-link-color;

      .dark-theme & {
        color: $post-link-color-dark;
      }
    }

    .dark-theme & {
      border-color: $global-border-color-dark;
    }
  }

  .footnotes {
    color: $global-font-secondary-color;

    .dark-theme & {
      color: $global-font-secondary-color-dark;
    }
  }

  @import "../_partial/_post/code";
  @import "../_partial/_post/admonition";

  .mermaid {
    width: 100%;
    margin: 3% auto;
    text-align: center;

    @import "../_mermaid/neutral/index";

    .dark-theme & {
      @import "../_mermaid/dark/index";
    }
  }

  @import "../_aplayer/dark.scss";

  .echarts {
    width: 100%;
    height: 30rem;
    margin: 3% auto;
    text-align: center;
  }

  .bilibili {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 75%;
    margin: 3% auto;
    text-align: center;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  hr {
    margin: 1rem 0;
    position: relative;
    border-top: 1px dashed $global-border-color;
    border-bottom: none;

    .dark-theme & {
      border-top: 1px dashed $global-border-color-dark;
    }
  }

  kbd {
    display: inline-block;
    padding: .25rem;
    background-color: $global-background-color;
    border: 1px solid $global-border-color;
    border-bottom-color: $global-border-color;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 -1px 0 $global-border-color;
    box-shadow: inset 0 -1px 0 $global-border-color;
    font-size: .8rem;
    font-family: $code-font-family;
    color: $code-color;

    .dark-theme & {
      background-color: $global-background-color-dark;
      border: 1px solid $global-border-color-dark;
      border-bottom-color: $global-border-color-dark;
      -webkit-box-shadow: inset 0 -1px 0 $global-border-color-dark;
      box-shadow: inset 0 -1px 0 $global-border-color-dark;
      color: $code-color-dark;
    }
  }

  .typeit {
    .code {
      padding: .375rem;
      font-size: .875rem;
      font-family: $code-font-family;
      font-weight: bold;
      word-break: break-all;
    }
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }
}

@import "../_partial/_post/footer";

@import "../_partial/_post/comment";
